import BackButton from "./Buttons/BackButton";
import List from "./List/List";

function Template({ name, subname, rules }) {
	return (
		<div className="bg-darker h-screen text-white overflow-y-auto" id="sug">
			<section className="h-auto min-h-screen flex place-content-center mx-4 sm:w-full sm:mx-auto">
				<div className="mt-8 sm:p-10 rounded-lg lg:w-1/2 font-raleway w-screen">
					<h1 className="text-center text-2xl">
						Règles
						<br />
						{name}
					</h1>
					<div className="mt-6 overflow-y-auto border-[1px] border-white/30 p-4 rounded-lg">
						{subname && <h2 className="text-xl pb-3">{subname}</h2>}
						<List data={rules} />
					</div>
					<BackButton />
				</div>
			</section>
		</div>
	);
}

Template.defaultProps = {
	subname: "",
};

export default Template;
