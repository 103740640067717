import PlayerCount from "./PlayerCount";
function Online() {
	return (
		<aside className="p-5 w-auto mx-auto sm:w-64 appear opacity-0 flex items-center place-content-center">
			<div className="bg-green-400 rounded-full pulsate w-4 h-4"></div>
			<span className="fixnum mt-[2px] ml-4">
				<PlayerCount /> en ligne
			</span>
		</aside>
	);
}

export default Online;
