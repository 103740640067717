import GoldenLogo from "../../Images/LogoGolden.png";
import MenuButton from "../Navigation/MenuButton";

function Header(props) {
	const handleClick = props.onClick;
	return (
		<header className="absolute">
			<nav className="mt-4 ml-2 flex py-2 pl-4">
				<img
					src={GoldenLogo}
					className="w-10 hover:cursor-pointer"
					alt="Golden Logo"
					onClick={handleClick}
				/>
				<MenuButton title="Retour" onClick={handleClick} />
			</nav>
		</header>
	);
}

export default Header;
