import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ButtonForm(props) {
	const [title, icon, direction, enabled, show, onClick] = [
		props.title,
		props.icon,
		props.direction,
		props.enabled,
		props.show,
		props.onClick,
	];
	const classes = "pointer-events-none opacity-30";

	return (
		<>
			{show && (
				<button
					disabled={!enabled}
					onClick={onClick}
					className={`font-semibold border-orange/90 border-2 mr-2 text-white px-4 py-2 rounded-lg inline-block mt-3 hover:bg-orange/90 hover:text-dark transition-all duration-300 ${
						!enabled ? classes : ""
					}`}
				>
					{direction === 0 && <FontAwesomeIcon icon={icon} className="mr-2" />}
					{title}
					{direction === 1 && <FontAwesomeIcon icon={icon} className="ml-2" />}
				</button>
			)}
		</>
	);
}

ButtonForm.defaultProps = {
	title: "Suivant",
	icon: faArrowRight,
	direction: 1,
	enabled: true,
	show: true,
	onClick: () => {},
};

export default ButtonForm;
