import Header from "./components/Global/Header";
import questions from "./Data/Suggestion/suggestion_questions.json";
import messages from "./Data/Suggestion/suggestion_messages.json";
import Form from "./components/Forms/Form";
import config from "./CONFIG";
import { useEffect, useState } from "react";

function Suggestions() {
	const [data, setData] = useState([]);
	window.onresize = () => {
		let rect = document.getElementById("sug").getBoundingClientRect();
		let home = document.getElementById("home").getBoundingClientRect();
		if (rect.top > 0 && rect.bottom > window.innerHeight && home.top !== 0) {
			document.getElementById("sug").scrollIntoView();
		}
	};

	useEffect(() => {
		fetch("https://api.goldenuhc.eu/suggest/categories", {
			method: "GET",
		}).then((res) => {
			res.json().then((data) => {
				setData(data);
			});
		});
	}, []);

	const handleClick = () => {
		document.getElementById("home").scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className="bg-darker min-h-screen h-auto text-white" id="sug">
			<Header onClick={handleClick} />
			<section className="w-full h-auto min-h-screen flex place-content-center">
				<div className="my-auto sm:p-10 rounded-lg lg:w-1/2 font-raleway w-screen">
					{config.suggestion ? (
						<Form
							title="Suggestions"
							questions={questions}
							select={data}
							postUrl="https://api.goldenuhc.eu/suggest"
							messages={messages}
							reset={true}
						/>
					) : (
						<div className="text-center font-raleway text-xl">
							Les suggestions sont fermées pour le moment.
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default Suggestions;
