function MenuButton(props) {
	const [title, link, onClick, classN] = [props.title, props.link, props.onClick, props.className];

	const classes =
		"sm:text-xs hover:bg-white scale-0 hover:bg-opacity-20 py-2 px-4 rounded-full transition-colors duration-300 text-center inline-block mt-3 sm:mt-0 text-sm mx-auto sm:mx-2 entrance " +
		classN;
	if (link) {
		return (
			<a
				className={classes}
				href={link}
				target={link.startsWith("/") ? "_self" : "_blank"}
				rel="noreferrer"
			>
				{title}
			</a>
		);
	}

	return (
		<button className={`${classes} font-semibold tracking-widest`} onClick={onClick}>
			{title}
		</button>
	);
}

MenuButton.defaultProps = {
	className: "w-32",
};

export default MenuButton;
