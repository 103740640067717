import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Applications from "./Applications";
import Rules from "./Rules";
import Host from "./components/regles/Host";
import Global from "./components/regles/Global";
import AOT from "./components/regles/AOT";
import DS from "./components/regles/DS";
import CSM from "./components/regles/CSM";
import Feedback from "./Feedback";

ReactDOM.render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/candidature" element={<Applications />} />
			<Route path="/feedback" element={<Feedback />} />
			<Route path="/regles" element={<Rules />} />
			<Route path="/regles/host" element={<Host />} />
			<Route path="/regles/global" element={<Global />} />
			<Route path="/regles/aot" element={<AOT />} />
			<Route path="/regles/ds" element={<DS />} />
			<Route path="/regles/csm" element={<CSM />} />
		</Routes>
	</BrowserRouter>,
	document.getElementById("root")
);
