import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CopyButton() {
	const [clicked, setClicked] = useState(false);

	function handleClick() {
		try {
			setClicked(true);
			setTimeout(() => {
				setClicked(false);
			}, 1900);
			navigator.clipboard.writeText("play.goldenuhc.eu");
		} catch (e) {
			alert("Erreur lors du copiage, veuillez le copier manuellement.");
		}
	}
	return (
		<>
			<button
				className={`mt-5 py-2 px-8 bg-white/30 rounded-lg font-raleway font-semibold text-lg transition-colors duration-300 block mx-auto appear opacity-0 ${
					clicked ? "bg-green-400/50" : ""
				}`}
				onClick={handleClick}
			>
				play.goldenuhc.eu <FontAwesomeIcon icon={faCopy} className="ml-2" />
			</button>
			<section className="relative">
				<div
					className={`text-center text-sm bg-green-400 sm:bg-green-400/50 py-2 w-1/2 sm:w-32 rounded-lg mt-5 absolute left-0 right-0 m-auto ${
						clicked ? "poof" : "hidden"
					}`}
				>
					Copié !
				</div>
			</section>
		</>
	);
}

export default CopyButton;
