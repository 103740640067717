import { useState } from "react";

function PlayerCount() {
	const [count, setCount] = useState(0);

	fetch("https://api.goldenuhc.eu/playercount", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => response.json())
		.then((data) => {
			setCount(data);
		});

	return count;
}

export default PlayerCount;
