import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../List.css";

function formatString(string) {
	const red = [
		"démons",
		"Kaigaku",
		"Hantengu",
		"Kyogai",
		"Kokushibo",
		"Muzan",
		"Enmu",
		"Daki",
		"Doma",
		"Gyutaro",
		"Susamaru",
		"Titan",
		"Jelena",
		"Niccolo",
		"Bestial",
	];
	const green = [
		"Sekkido",
		"Kagaya",
		"Mitsuri",
		"Nezuko",
		"Obanai",
		"Tomioka",
		"Urokodaki",
		"Kanae",
		"Zenitsu",
		"Éclaireur",
		"Armin",
		"Falco",
		"Soldat",
		"Soldats",
		"Kyojuro",
	];
	const blue = ["Shifters", "Shifter", "Charette"];
	const gold = ["Yoriichi", "Jigoro", "Shinjuro", "Gabi"];

	let final = [];

	string?.split(/([ ]|[.]|[']|[,])/g).forEach((item) => {
		console.log(item);
		if (red.some((v) => item.includes(v)) && !item !== "AttackOnTitanUHC") {
			final.push(<span className="text-red-500">{item}</span>);
		} else if (green.some((v) => item.includes(v))) {
			final.push(<span className="text-green-400">{item}</span>);
		} else if (gold.some((v) => item.includes(v))) {
			final.push(<span className="text-yellow-500">{item}</span>);
		} else if (blue.some((v) => item.includes(v))) {
			final.push(<span className="text-blue-500">{item}</span>);
		} else {
			final.push(<span>{item}</span>);
		}
	});

	return final;
}

function List({ data }) {
	const listItems = data.map((item, index) => {
		return (
			<>
				<li key={index} className={item.new ? "new" : ""}>
					{formatString(item.rule)}
					{item.new && (
						<>
							<sup className="text-cyan-300 font-semibold ml-2">NEW</sup>
						</>
					)}
					{item.description && (
						<>
							<br />{" "}
							{item.description.split("\n").map((line, index) => {
								return (
									<>
										<small key={index}>{formatString(line)}</small>
										<br />
									</>
								);
							})}
						</>
					)}
					{item.warning && (
						<>
							<br />
							{item.warning.split("\n").map((line, index) => {
								return (
									<>
										<FontAwesomeIcon
											icon={faExclamationCircle}
											className="text-yellow-400 text-sm mr-2"
										/>
										<span key={index} className="text-sm">
											{formatString(line)}
										</span>
										<br />
									</>
								);
							})}
						</>
					)}
				</li>
			</>
		);
	});
	return <ol className="main">{listItems}</ol>;
}

export default List;
