import Header from "./components/Global/Header";
import { useNavigate } from "react-router-dom";
import Form from "./components/Forms/Form";
import questions from "./Data/Applications/application_questions.json";
import messages from "./Data/Applications/application_messages.json";
import config from "./CONFIG";

function Applications() {
	let navigate = useNavigate();
	const handleClick = () => {
		navigate("/");
	};

	return (
		<div className="bg-darker min-h-screen h-auto text-white" id="sug">
			<Header onClick={handleClick} />
			<section className="w-full h-auto min-h-screen flex place-content-center">
				<div className="my-auto sm:p-10 rounded-lg lg:w-1/2 font-raleway w-screen">
					{config.apply ? (
						<Form
							title="Candidatures"
							questions={questions}
							postUrl="https://api.goldenuhc.eu/application"
							messages={messages}
							reset={false}
						/>
					) : (
						<div className="text-center font-raleway text-xl">
							Les candidatures sont fermées pour le moment.
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default Applications;
