function SelectForm(props) {
	const [values, input, setInput] = [props.values, props.input, props.setInput];

	function handleChange(e) {
		setInput(e.target.selectedOptions[0].getAttribute("value"));
	}

	return (
		<>
			<select
				id="answer"
				className="bg-transparent border-b-2 border-b-orange text-orange w-full py-2 px-4 focus:outline-none"
				onChange={handleChange}
				defaultValue={input === "" ? "1" : input}
			>
				{values.map((value) => (
					<option className="bg-darker" key={value.id} value={value.id}>
						{value.name}
					</option>
				))}
			</select>
		</>
	);
}

export default SelectForm;
