import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./components/Global/Footer";
import Particles from "./components/Global/Particles";
import Partners from "./components/Global/Partners";
import CopyButton from "./components/Landing/CopyButton";
import Online from "./components/Landing/Online";
import MenuButton from "./components/Navigation/MenuButton";
import GoldenLogo from "./Images/LogoGolden.png";
import Suggestions from "./Suggestions";

function Home() {
	const navigate = useNavigate();

	const [image, setImage] = useState("");
	const handleClick = () => {
		document.getElementById("sug").scrollIntoView({ behavior: "smooth" });
	};

	function getRandomInt(max) {
		return Math.floor(Math.random() * max);
	}

	useEffect(() => {
		let int = getRandomInt(3);
		if (int === 0) {
			setImage("bg-bgone");
		} else if (int === 1) {
			setImage("bg-bgtwo");
		} else {
			setImage("bg-bgthree");
		}
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			window.scrollTo(0, 0);
		};
	});

	return (
		<>
			<div className={`background h-auto min-h-screen relative -z-20 ${image}`} id="home">
				<main className="h-auto min-h-screen bg-black/40 relative -z-20">
					<div className="relative -z-10 hidden md:block">
						<Particles number="100" />
					</div>
					<Partners className="hidden sm:flex" />
					<div className="h-auto min-h-screen text-white font-raleway font-semibold xs:pt-10 tracking-widest sm:mx-auto sm:pt-24">
						<img src={GoldenLogo} className="w-52 sm:w-96 mx-auto entrance" alt="Golden Logo" />
						<nav className="mt-6 flex flex-col sm:block sm:text-center mb-2">
							<MenuButton title="DISCORD" link="https://discord.gg/" />
							<MenuButton title="RÈGLES" onClick={() => navigate("/regles")} />
							<MenuButton title="BOUTIQUE" link="https://boutique.goldenuhc.eu" />
							<MenuButton title="SUGGESTIONS" onClick={handleClick} />
							<MenuButton title="DOCS" link="https://docs.goldenuhc.eu" />
							<MenuButton title="BUG" onClick={() => navigate("/feedback")} />
						</nav>
						<div>
							<Online />
							<CopyButton />
						</div>
						<Partners className="place-content-center mt-2 hidden xs:flex xs:static sm:hidden" />
						<Footer />
					</div>
				</main>
			</div>
			<Suggestions />
		</>
	);
}

export default Home;
