import GoldenLogo from "./Images/LogoGolden.png";
import MenuButton from "./components/Navigation/MenuButton";
import Particles from "./components/Global/Particles";
import Footer from "./components/Global/Footer";
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Rules() {
	const navigate = useNavigate();

	return (
		<>
			<div className={`background h-auto min-h-screen relative -z-20 bg-bgtwo`} id="home">
				<main className="h-auto min-h-screen bg-black/60 relative -z-20">
					<div className="relative -z-10 hidden md:block">
						<Particles number="100" />
					</div>
					<div className="h-auto min-h-screen text-white font-raleway font-semibold xs:pt-10 tracking-widest sm:mx-auto sm:pt-24">
						<img src={GoldenLogo} className="w-52 sm:w-96 mx-auto entrance" alt="Golden Logo" />
						<nav className="mt-6 flex flex-col sm:flex-row sm:text-center justify-center">
							<MenuButton title="RÈGLES HOST" onClick={() => navigate('/regles/host')} className="w-44" />
							<MenuButton title="RÈGLES GLOBALES" onClick={() => navigate('/regles/global')} className="w-44" />
						</nav>
						<nav className="mt-6 flex flex-col sm:flex-row sm:text-center justify-center">
							<MenuButton title="RÈGLES AOT" onClick={() => navigate('/regles/aot')} className="w-44" />
							<MenuButton title="RÈGLES DS" onClick={() => navigate('/regles/ds')} className="w-44" />
							<MenuButton title="RÈGLES CSM" onClick={() => navigate('/regles/csm')} className="w-44" />
						</nav>


						<button
							className={`mt-5 py-2 px-8 bg-white/30 rounded-lg font-raleway font-semibold text-lg transition-colors duration-300 block mx-auto appear opacity-0`}
							onClick={() => navigate('/')}
						>
							<FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Retour 
						</button>

						<Footer />
					</div>
				</main>
			</div>
		</>
	);
}

export default Rules;
