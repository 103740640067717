import { Link } from "react-router-dom";
function BackButton() {
	return (
		<div className="flex justify-center">
			<Link to="/regles" className="p-4">
				<div className="rounded-lg bg-red-500 p-4 text-center w-36 inline-block mx-auto hover:bg-red-700 transition-colors duration-300">
					Retour
				</div>
			</Link>
		</div>
	);
}

export default BackButton;
