function InputForm(props) {
	const [placeholder, text, setInput] = [props.placeholder, props.text, props.setInput];

	function handleText(e) {
		setInput(e.target.value);
	}
	return (
		<>
			<input
				className="rouded-lg border-b-2 border-orange bg-transparent px-4 pt-4 pb-2 w-full focus:outline-none text-orange placeholder:text-orange/50"
				id="answer"
				placeholder={placeholder}
				value={text}
				onChange={handleText}
			></input>
		</>
	);
}

InputForm.defaultProps = {
	placeholder: "Écrivez votre réponse ici",
};

export default InputForm;
