import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

function HeadersForms(props) {
	const [title, question, lastQ] = [props.title, props.question, props.last];
	const [affichage, setAffichage] = useState("");

	useEffect(() => {
		setAffichage(lastQ.question);
		document.getElementById("question").classList.add("slideout");
	}, [question.question, lastQ.question]);

	function handleAnimationEnd(e) {
		let q = document.getElementById("question");
		if (q.classList.contains("slideout")) {
			setAffichage(question.question);
			q.classList.remove("slideout");
			q.classList.add("slidein");
		} else {
			q.classList.remove("slidein");
		}
	}
	return (
		<hgroup className="overflow-x-hidden">
			<h1 className="text-[2rem] mb-10 text-center">{title}</h1>
			<h2 id="question" className="text-lg mb-5 px-4" onAnimationEnd={handleAnimationEnd}>
				<FontAwesomeIcon icon={faArrowRightLong} className="text-xs text-orange mr-2" />
				{affichage}
			</h2>
		</hgroup>
	);
}

export default HeadersForms;
