import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import AreaForm from "./AreaForm";
import ButtonForm from "./ButtonForm";
import HeadersForms from "./HeadersForm";
import InputForm from "./InputForm";
import Recap from "./Recap";
import SelectForm from "./SelectForm";

function Form(props) {
	const [title, questions, postUrl, messages, reset] = [
		props.title,
		props.questions,
		props.postUrl,
		props.messages,
		props.reset,
	];
	const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
	const [lastQuestion, setLastQuestion] = useState(questions[0]);
	const [input, setInput] = useState("");
	const [json, setJson] = useState(new Map());
	let showBackButton = false;
	let enabledNextButton = true;
	let showNextButton = true;
	let selector;
	switch (currentQuestion.type) {
		case "select":
			selector = <SelectForm values={props.select} setInput={setInput} input={input} />;
			break;
		case "textarea":
			selector = (
				<AreaForm placeholder={currentQuestion.placeholder} text={input} setInput={setInput} />
			);
			break;
		case "end":
			if (currentQuestion.question.includes("erreur")) {
				selector = <ButtonForm title="Réessayer" icon={faArrowRight} direction={1} onClick={end} />;
			} else if (reset) {
				selector = (
					<ButtonForm
						title="Nouvelle Suggestion"
						icon={faArrowRight}
						direction={1}
						onClick={resetAll}
					/>
				);
			} else {
				selector = null;
			}
			showBackButton = false;
			showNextButton = false;
			break;
		case "recap":
			selector = <Recap questions={questions} answers={json} />;
			break;
		default:
			selector = (
				<InputForm placeholder={currentQuestion.placeholder} text={input} setInput={setInput} />
			);
			break;
	}

	function resetAll() {
		setCurrentQuestion(questions[0]);
		setInput("");
		document.getElementById("question").style.color = "white";
		setJson(new Map());
	}

	function next(e) {
		e.preventDefault();

		let nextQuestion = questions.find((question) => question.id === currentQuestion.id + 1);
		let value = input;
		//end
		if (currentQuestion.id > questions[questions.length - 1].id) {
			end(value);
			return;
		}

		// recap
		if (nextQuestion === undefined) {
			nextQuestion = {
				id: currentQuestion.id + 1,
				question: "Récapitulatif",
				type: "recap",
			};
		}

		// Press back and then next
		if (json.has(nextQuestion.json_name)) {
			saveQuestion(value);
			setInput(json.get(nextQuestion.json_name));
		}
		// Pressed next
		else {
			saveQuestion(value);
		}

		// next question is conditional
		if (nextQuestion.accept) {
			if (value === "" && currentQuestion.type === "select") {
				value = "1";
			}
			value = getCorrectType(value);
			if (nextQuestion.accept.indexOf(value) === -1) {
				if (json.has(nextQuestion.json_name)) {
					// delete incase user tries to cheese and do back -> next -> back -> next
					let newMap = new Map(json);
					newMap.delete(nextQuestion.json_name);
					setJson(newMap);
				}
				nextQuestion = questions.find((question) => question.id === currentQuestion.id + 2);
			}
		}
		setLastQuestion(currentQuestion);
		setCurrentQuestion(nextQuestion);
	}

	function back(e) {
		e.preventDefault();
		let previousQuestion = questions.find((question) => question.id === currentQuestion.id - 1);

		// second previous question is conditional
		if (json.get(previousQuestion.json_name) === undefined) {
			previousQuestion = questions.find((question) => question.id === currentQuestion.id - 2);
		}
		setLastQuestion(currentQuestion);
		setCurrentQuestion(previousQuestion);

		switch (previousQuestion.type) {
			case "select":
				setInput(json.get(previousQuestion.json_name));
				break;
			default:
				setInput(json.get(previousQuestion.json_name));
				break;
		}
	}

	function end(value) {
		saveQuestion(value);
		setCurrentQuestion(messages.find((message) => message.id === "SENDING_MESSAGE"));
		fetch(postUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json", //this must be set to a json type
			},
			body: JSON.stringify(Object.fromEntries(json.entries())),
		})
			.then(() => {
				setCurrentQuestion(messages.find((message) => message.id === "THANKYOU_MESSAGE"));
				document.getElementById("question").style.color = "#98ff98";
			})
			.catch(() => {
				setCurrentQuestion(messages.find((message) => message.id === "ERROR_MESSAGE"));
				document.getElementById("question").style.color = "#FFCCCC";
			});
	}

	function saveQuestion(value) {
		if (value === "" && currentQuestion.type === "select") {
			value = "1";
		}
		value = getCorrectType(value);
		setInput("");
		setJson(json.set(currentQuestion.json_name, value));
	}

	function getCorrectType(value) {
		switch (currentQuestion.json_type) {
			case "int":
				return parseInt(value);
			default:
				return value.toString();
		}
	}

	if (currentQuestion.id > 1) {
		showBackButton = true;
	}

	if (
		(currentQuestion.type === "text" || currentQuestion.type === "textarea") &&
		input.length < 1 &&
		!currentQuestion.is_optional
	) {
		enabledNextButton = false;
	}

	return (
		<form className="px-5 sm:px-0 max-h-[80vh] overflow-y-auto overflow-x-hidden">
			<HeadersForms title={title} question={currentQuestion} last={lastQuestion} />
			{selector}
			<ButtonForm
				title="Précédent"
				direction={0}
				icon={faArrowLeft}
				enabled={showBackButton}
				show={showBackButton}
				onClick={back}
			/>
			<ButtonForm onClick={next} enabled={enabledNextButton} show={showNextButton} />
		</form>
	);
}

Form.defeaultProps = {
	select: null,
};

export default Form;
