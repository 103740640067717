import logoEkalia from "../../Images/logo_ekalia.png";
import logoPrivateHeberg from "../../Images/logo_privateheberg.png";

function Partners(props) {
	return (
		<section className={`absolute top-2 right-4 text-white appear opacity-0 ${props.className}`}>
			<h6 className="mr-5 my-auto font-raleway font-semibold text-sm">Partenaires</h6>
			<a
				className="bg-black/60 rounded-lg p-4 hover:bg-white/50 transition-colors duration-300"
				href="https://ekalia.fr/"
				target="_blank"
				rel="noreferrer"
			>
				<img src={logoEkalia} alt="Ekalia Logo" className="w-8" title="Ekalia"></img>
			</a>

			<a
				className="bg-black/60 rounded-lg p-4 hover:bg-white/50 transition-colors duration-300"
				href="https://privateheberg.net/"
				target="_blank"
				rel="noreferrer"
			>
				<img src={logoPrivateHeberg} alt="PrivateHeberg Logo" className="w-8" title="PrivateHeberg"></img>
			</a>
		</section>
	);
}

export default Partners;
