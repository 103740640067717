import Particless from "react-tsparticles";

function Particles(props) {
	const particlesInit = (main) => {};

	const particlesLoaded = (container) => {};

	return (
		<Particless
			id="tsparticles"
			init={particlesInit}
			loaded={particlesLoaded}
			params={{
				fpsLimit: 60,
				particles: {
					number: {
						value: props.number,
					},
					color: {
						value: "#de2c2c",
					},
					links: {
						color: "#ffffff",
						distance: 150,
						enable: true,
						opacity: 0.4,
						width: 1,
					},

					move: {
						enable: true,
					},
				},
				interactivity: {
					events: {
						onHover: {
							enable: true,
							mode: ["grab", "connect"],
						},
					},
					detectsOn: "window",
					modes: {
						grab: {
							distance: 150,
							line_linked: {
								opacity: 1,
								color: {
									value: "#ffffff",
								},
							},
						},
					},
				},
			}}
		/>
	);
}

export default Particles;
