function Footer() {
	return (
		<footer className="footer fixnum absolute bottom-0 w-full px-2 text-white/30 sm:text-sm font-medium text-xs block">
			<div className="flex place-content-between">
				<p className="">© Copyright 2022 GoldenUHC - Tous droits réservés</p>
				<a
					className="text-right"
					href="https://discordapp.com/users/370962656447954944/"
					target="_blank"
					rel="noreferrer"
				>
					Fait par Sxm#7720
				</a>
			</div>
		</footer>
	);
}

export default Footer;
