function AreaForm(props) {
	const [placeholder, text, setInput] = [props.placeholder, props.text, props.setInput];

	function handleText(e) {
		setInput(e.target.value);
	}

	return (
		<>
			<textarea
				className="rouded-lg border-b-2 border-orange bg-transparent px-4 pt-4 pb-2 w-full focus:outline-none text-orange placeholder:text-orange/50"
				rows={10}
				maxLength={4000}
				placeholder={placeholder}
				id="answer"
				value={text}
				onChange={handleText}
			></textarea>
		</>
	);
}

export default AreaForm;
