import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Recap(props) {
	const [questions, answers] = [props.questions, props.answers];
	let show = [];
	questions.forEach((question) => {
		if (answers.has(question.json_name)) {
			show.push({ question: question.question, answer: answers.get(question.json_name) });
		}
	});
	return (
		<div className="flex flex-col">
			{show.map((info, index) => (
				<>
					<div className="my-2" key={index}>
						<h2 className="text-left text-lg font-raleway font-medium text-orange">
							{info.question}
						</h2>
						<FontAwesomeIcon icon={faArrowRightLong} className="text-sm text-orange mr-2" />
						<p className="text-left text-base font-normal inline-block break-all">{info.answer}</p>
					</div>
				</>
			))}
		</div>
	);
}

export default Recap;
