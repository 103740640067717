import Header from "./components/Global/Header";
import questions from "./Data/Feedback/feedback_questions.json";
import messages from "./Data/Feedback/feedback_messages.json";
import Form from "./components/Forms/Form";
import config from "./CONFIG";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Feedback() {
	let navigate = useNavigate();
	const [data, setData] = useState([]);

	useEffect(() => {
		fetch("https://api.goldenuhc.eu/feedback/categories", {
			method: "GET",
		}).then((res) => {
			res.json().then((data) => {
				setData(data);
			});
		});
	}, []);

	const handleClick = () => {
		navigate("/");
	};

	return (
		<div className="bg-darker min-h-screen h-auto text-white" id="sug">
			<Header onClick={handleClick} />
			<section className="w-full h-auto min-h-screen flex place-content-center">
				<div className="my-auto sm:p-10 rounded-lg lg:w-1/2 font-raleway w-screen">
					{config.feedback ? (
						<Form
							title="Retours & Bugs"
							questions={questions}
							select={data}
							postUrl="https://api.goldenuhc.eu/feedback"
							messages={messages}
							reset={true}
						/>
					) : (
						<div className="text-center font-raleway text-xl">
							Les retours sont fermés pour le moment.
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default Feedback;
